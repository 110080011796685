import { useState } from 'react'
import { reverse } from 'named-urls'
import { isEmpty } from 'lodash'

import { Box, Button, Divider, Paper, Typography } from '@mui/material'
import { PlusCircle as PlusIcon } from 'react-feather'

import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'hooks'

import { formatters } from 'helpers'

import routes from 'constants/routes'
import service from 'service'

import { ConfirmDestroy, EvidencePlanActionForm } from './components'

import schema, { PlanActionSchemaType, QuestionFormSchemaType } from './schema'
import { ActionPlanFormType, EvidencePlanAction } from './ActionPlanForm.types'

const ActionPlanForm = ({
  questionnaireId,
  quizQuestionId,
  evidencePlanActions,
  setIsLoading,
  formName,
  refresh,
}: ActionPlanFormType) => {
  const { snackbar } = useSnackbar()
  const navigate = useNavigate()

  const [registerName, setRegisterName] = useState<string>()
  const [destroyFunction, setDestroyFunction] = useState<VoidFunction>()

  const [evidences, setEvidences] = useState(evidencePlanActions ?? [])

  const { handleSubmit, ...rest } = useForm<QuestionFormSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      evidencePlanActions:
        evidencePlanActions?.map((evidencePlanAction) => ({
          ...evidencePlanAction,
          planActions:
            evidencePlanAction?.planActions?.map((planAction) => ({
              ...planAction,
              howPrice: formatters.money.input(planAction?.howPrice),
            })) || [],
        })) || [],
    },
  })

  const onSubmit = async (data: QuestionFormSchemaType) => {
    try {
      setIsLoading(true)

      for await (const evidencePlanAction of data?.evidencePlanActions ?? []) {
        evidencePlanAction.planActions = evidencePlanAction?.planActions?.map(
          (planAction) => {
            return {
              ...planAction,
              ...(planAction?.howPrice
                ? {
                    howPrice: String(
                      formatters.money.convert(planAction.howPrice)
                    ),
                  }
                : {}),
            }
          }
        ) as PlanActionSchemaType[]

        if (evidencePlanAction.id) {
          await service.dponet.evidencePlanActions.update({
            quizQuestionTemplateId: quizQuestionId,
            evidencePlanActionId: parseInt(evidencePlanAction?.id?.toString()),
            params: { evidencePlanAction },
          })
        } else {
          await service.dponet.evidencePlanActions.create({
            quizQuestionTemplateId: quizQuestionId,
            params: { evidencePlanAction },
          })
        }
      }

      refresh()

      snackbar.open({
        message: `Evidências salvas com sucesso!`,
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const redirectToMain = () => {
    if (questionnaireId) {
      navigate(
        reverse(routes.app.questionnaires.edit, {
          questionnaireId: questionnaireId,
        })
      )
    }
  }

  const handleAddEvidence = () => {
    const newState = [
      ...((rest.watch('evidencePlanActions') ?? []) as EvidencePlanAction[]),
      {
        evidence: {
          description: '',
          justify: '',
          title: '',
        },
        planActions: [],
      },
    ]

    setEvidences(newState)
    rest.setValue('evidencePlanActions', newState)
    setTimeout(
      () =>
        window.scrollTo({
          top: document.body.scrollHeight - 1200,
          behavior: 'smooth',
        }),
      200
    )
  }

  const handleDestroyRegister = async (handleDestroy: () => void) => {
    setDestroyFunction(handleDestroy)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={formName}>
      <FormProvider handleSubmit={handleSubmit} {...rest}>
        <Paper component={Box} mt={8}>
          <Box
            p={4}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Evidências</Typography>
            <Button
              onClick={handleAddEvidence}
              variant="contained"
              startIcon={<PlusIcon />}
            >
              Adicionar evidência
            </Button>
          </Box>
          <Divider />
          {isEmpty(evidences) && (
            <Box py={4} ml={4}>
              <Typography variant="body1" color="GrayText">
                Não há evidências cadastradas
              </Typography>
            </Box>
          )}
          {evidences?.map((evidencePlanAction, index) => (
            <EvidencePlanActionForm
              key={index}
              position={index}
              evidencePlanAction={evidencePlanAction}
              evidencePlanActions={evidences}
              setEvidencePlanActions={setEvidences}
              handleDestroyRegister={handleDestroyRegister}
              quizTemplateId={questionnaireId}
              quizQuestionTemplateId={quizQuestionId}
              setRegisterName={setRegisterName}
            />
          ))}
        </Paper>
        <Box display="flex" justifyContent="end" marginTop={4} gap={2}>
          <Button variant="outlined" onClick={redirectToMain}>
            Voltar
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Salvar
          </Button>
        </Box>
      </FormProvider>
      <ConfirmDestroy
        open={!!destroyFunction}
        onClose={() => setDestroyFunction(undefined)}
        handleDestroy={destroyFunction}
        registerName={registerName}
      />
    </form>
  )
}

export default ActionPlanForm

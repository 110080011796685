import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Paper,
  Select,
  TextField,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { reverse } from 'named-urls'

import { useSnackbar } from 'hooks'

import schema, { QuestionFormSchemaType } from './schema'
import constants from 'constants/index'
import routes from 'constants/routes'
import { formatters } from 'helpers'
import service from 'service'

import { QuestionFormType } from './questionForm.types'
import { MUI } from 'components'

const QuestionForm = ({
  questionnaireId,
  quizQuestionId,
  quizQuestionData,
  setIsLoading,
  formName,
}: QuestionFormType) => {
  const navigate = useNavigate()
  const { snackbar } = useSnackbar()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<QuestionFormSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      theme: quizQuestionData?.theme || '',
      question: quizQuestionData?.question || '',
      explanation: quizQuestionData?.explanation || '',
      purpose: quizQuestionData?.purpose || '',
      securityPolicy: quizQuestionData?.securityPolicy || '',
      conformityLevel: quizQuestionData?.conformityLevel || 1,
      videoUrl: quizQuestionData?.videoUrl || '',
    },
  })

  const redirectToEdition = () => {
    if (questionnaireId && !quizQuestionData) {
      navigate(
        reverse(routes.app.questionnaires.edit, {
          questionnaireId: questionnaireId,
        })
      )
    }
  }

  const onSubmit = async (data: QuestionFormSchemaType) => {
    setIsLoading(true)
    try {
      quizQuestionData
        ? await service.dponet.questionnaires.updateQuestion({
            quizTemplateId: questionnaireId,
            quizQuestionId: quizQuestionId,
            quizQuestion: {
              ...data,
            },
          })
        : await service.dponet.questionnaires.createQuestion({
            quizTemplateId: questionnaireId,
            quizQuestion: {
              ...data,
            },
          })

      snackbar.open({
        message: `Questão ${
          quizQuestionData ? 'editada' : 'criada'
        } com sucesso!`,
        variant: 'success',
      })

      redirectToEdition()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={formName}>
      <Paper component={Box} p={4}>
        <Box display="flex" flexDirection="column" gap={5}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth margin="dense">
                <InputLabel id="conformity-level-select-label">
                  Nível de conformidade
                </InputLabel>
                <Select
                  {...field}
                  error={!!errors?.conformityLevel}
                  labelId="conformity-level-select-label"
                  id="conformity-level-select"
                  label="Nivel de conformidade"
                  disabled={!!quizQuestionData?.conformityLevel}
                >
                  {constants.questionnaire.CONFORMITY_LEVELS.map((level) => (
                    <MUI.MenuItem key={level.value} value={level.value}>
                      {level.label}
                    </MUI.MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!errors.conformityLevel}>
                  {errors?.conformityLevel?.message}
                </FormHelperText>
              </FormControl>
            )}
            name="conformityLevel"
            control={control}
            defaultValue={1}
          />

          {constants.questionnaire.QUESTION_FORM_FIELDS.map((formField) => (
            <Controller
              key={formField.name}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.[formField.name]}
                  helperText={errors?.[formField.name]?.message}
                  label={formField.label}
                  type="text"
                  variant="outlined"
                  fullWidth
                  multiline={formField.multiline}
                  rows={formField.rows}
                  sx={{ backgroundColor: 'white' }}
                />
              )}
              control={control}
              name={formField.name}
            />
          ))}
        </Box>
      </Paper>
      <Box gap={2} display="flex" justifyContent="end" marginTop={4}>
        {!quizQuestionId && (
          <Button onClick={redirectToEdition} variant="outlined">
            Voltar
          </Button>
        )}
        <Button type="submit" variant="contained" color="primary">
          Salvar
        </Button>
      </Box>
    </form>
  )
}

export default QuestionForm

import * as yup from 'yup'

const schema = yup.object({
  theme: yup.string().max(100).required(),
  question: yup.string().max(1000).required(),
  explanation: yup.string().max(1000).required(),
  purpose: yup.string().max(1000).required(),
  securityPolicy: yup.string().max(1000).required(),
  conformityLevel: yup.number().required(),
  videoUrl: yup.string().url(),
})

export type QuestionFormSchemaType = yup.InferType<typeof schema>

export type QuestionFormControlType = keyof QuestionFormSchemaType

export default schema
